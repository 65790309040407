<template>
  <div>
    <b-card title="电话配置">
      <el-divider content-position="left">禁启用配置</el-divider>
      <b-row>
        <b-col>
          <span>{{'电话剩余条数:'+tenantForm.vmsRemainingCount}}</span>
        </b-col>
        <b-col>
          启用通知:
          <el-switch
            :disabled="!$checkButtonPermission('208002001')"
            v-model="tenantForm.vmsEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="switchEnabled"
            >
          </el-switch>
        </b-col>
      </b-row>
      <el-divider content-position="left">电话通知配置</el-divider>
      <b-row>
        <b-col>
          启用配置:
          <el-switch
            :disabled="!$checkButtonPermission('208002002')"
            v-model="tenantForm.vmsConfigEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="switchConfigEnabled"
            >
          </el-switch>
        </b-col>
        <b-col>
          若不启用，则使用平台默认参数
        </b-col>
      </b-row>
      <el-divider content-position="left">电话通知参数</el-divider>
      <b-row class="my-1">
        <b-col sm="4">
          <b-form-group
            label-cols-sm="6"
            label-cols-lg="4"
            content-cols-lg="7"
            description="单位:分钟"
            label="电话推送间隔"
            label-for="input-horizontal"
          >
            <b-form-input id="input-horizontal"  type="number"  v-model="tenantForm.vmsInterval "  @change="(e)=>(tenantForm.vmsInterval =numberCheck(e))"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label-cols-sm="6"
            label-cols-lg="4"
            content-cols-lg="7"
            description="单位:次"
            label="电话推送上限"
            label-for="input-horizontal"
          >
            <b-form-input id="input-horizontal" type="number" v-model="tenantForm.vmsCeiling" @change="(e)=>(tenantForm.vmsCeiling=numberCheck(e))"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" v-if="$checkButtonPermission('208002003')" @click="saveForm">保存</b-button>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar,BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import request from '@/api/request'

export default {
  components: {
    BCard,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      tenantForm:{
        id: "",
        vmsCeiling: 0,
        vmsConfigEnabled: false,
        vmsEnabled: false,
        vmsInterval: 0,
        vmsRemainingCount: 0
      },

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getTenantConfig()
  },
  methods: {
    getTenantConfig(){
      request.get('tob/tenant/tenantSysConfig/query').then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.tenantForm = {
            id: res.data.data.id,
            vmsCeiling: res.data.data.vmsCeiling,
            vmsConfigEnabled: res.data.data.vmsConfigEnabled,
            vmsEnabled: res.data.data.vmsEnabled,
            vmsInterval: res.data.data.vmsInterval,
            vmsRemainingCount: res.data.data.vmsRemainingCount
          }
        }
      })
    },
    switchEnabled(val){
      let parmas = {
        id: this.tenantForm.id,
        msType: 1,
        flag: val
      }
      request.post('tob/tenant/tenantSysConfig/enabledMs',parmas).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
        }
      })
    },
    switchConfigEnabled(val){
      let parmas = {
        id: this.tenantForm.id,
        msType: 1,
        flag: val
      }
      request.post('tob/tenant/tenantSysConfig/enabledMsConfig',parmas).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
        }
      })
    },

    saveForm(){
      let params = {
        id: this.tenantForm.id,
        msType: 1,
        interval: this.tenantForm.vmsInterval,
        ceiling: this.tenantForm.vmsCeiling
      }
      request.post('tob/tenant/tenantSysConfig/saveMsConfig',params).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
        }
      })
    },

    numberCheck(value){
      let strArray = value.split(".");
      return parseInt(strArray[0]);
    },

  },
}
</script>

<style lang="scss">
</style>
